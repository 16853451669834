const STATE = ''

export const SOCKET_DEBUG = false;
export const URL = 'https://meloa.inesctec.pt'

export const URL_CATALOGUE = `http://catalogue${STATE}.ec-meloa.eu/dataset/`

export const API_URL = `${URL}/api`;
export const WEBSOCKET_URL = `${URL}/`;
export const AUTH_PROVIDER = `${API_URL}/users/login`;

export const KEY_BING = 'Any3CC8Wt7cBdCjMNjsEeStoX7tBn2W_H6ueqKzSSiOvLh_HQEQhvSBZj7QzYyeZ';
export const KEY_GOOGLE = 'AIzaSyB7DgboLb_ybkgObTqlSg0w_5Wh5UH_gXk';
export const WAVY_OCEAN_MODELS = [4];